import React from "react";
import "./contact.css";
import Time from "../assets/img/time-left.png";
import Email from "../assets/img/email.png";
import Phone from "../assets/img/phone.png";
import Location from "../assets/img/location.png";
import Instagram from "../assets/img/instagram.png";
import Linkedin from "../assets/img/linkedin.png";

const Contact = () => {
  return (
    <>
      <div className="contactPage">
        <div className="topper">
          <h1>Feel free to contact us or just say hi!</h1>
        </div>
        <div className="contactUs">
          <div>
            <div className="headingContact">
              <h1 className="font-face-sa">Let's Connect Easily</h1>
            </div>
            <div className="map">
            {/* <iframe  width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              <iframe
                width="420"
                height="620"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
               src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3766.9384903310547!2d72.8656196747246!3d19.241512296756703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sShop%20no%2024%20Dhavalgiri%20building%20Hanuman%20tekdi%20Ashokan%20dahisar%20East%C2%A0Mumbai%C2%A0400068!5e0!3m2!1sen!2sin!4v1728696464598!5m2!1sen!2sin"
              >
                <a href="https://www.maps.ie/population/">
                  Find Population on Map
                </a>
              </iframe>
            </div>
            <div className="right-contact">
              <div className="textareas">
                <div id="contact-page-form">
                  <div className="left">
                    <label> First Name* </label>
                    <span>
                      <input
                        size="40"
                        aria-required="true"
                        aria-invalid="false"
                        value=""
                        type="text"
                        name="first-name"
                      />
                    </span>
                  </div>
                  <div className="right">
                    <label> Last Name* </label>
                    <span>
                      <input
                        size="40"
                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                        aria-required="true"
                        aria-invalid="false"
                        value=""
                        type="text"
                        name="last-name"
                      />
                    </span>
                  </div>
                </div>
                <br />
                <div className="clearfix">
                  <div className="left">
                    <label> Email* </label>
                    <span>
                      <input
                        size="40"
                        className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                        aria-required="true"
                        aria-invalid="false"
                        value=""
                        type="email"
                        name="your-email"
                      />
                    </span>
                  </div>
                  <div className="right">
                    <label className="phone"> Phone*</label>
                    <span className="wpcf7-form-control-wrap phone">
                      <span className="contactform7_container_mobile wpnotif_phone_field_container">
                        <span className="phonefield">
                          <span className="countrycodecontainer">
                            <input
                              type="text"
                              name="countrycode_phone"
                              className="wpnotif_countrycode"
                              value="+91"
                              maxlength="6"
                              size="3"
                              placeholder="+91"
                            />
                          </span>
                          <span className="phone-text">
                            <input
                              className="phone-text"
                              aria-required="true"
                              aria-invalid="false"
                              value=""
                              type="text"
                              name="phone"
                            />
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <br />
                <div className="clearfix">
                  <div className="left">
                    <label> Business / Organisation</label>
                    <span className="wtext">
                      <input
                        size="40"
                        className="wtext"
                        aria-invalid="false"
                        value=""
                        type="text"
                        name="business-name"
                      />
                    </span>
                  </div>
                  <div className="right">
                    <span className="wtext2">
                      <label> Existing Website (if applicable) </label>

                      <input
                        size="40"
                        className="wpcf7-form-control wpcf7-url wpcf7-text wpcf7-validates-as-url"
                        aria-invalid="false"
                        placeholder="https//"
                        value=""
                        type="url"
                        name="url"
                      />
                    </span>
                  </div>
                </div>
                <div className="midForm">
                  <span className="pricingPlans">
                    <label> Existing Website (if applicable) </label>
                    <select className="">
                      <option hidden>
                        Select an option
                      </option>
                      <option>STANDARD PLAN</option>
                      <option>PRO PLAN</option>
                      <option>PREMIUM PLAN</option>
                      <option>CUSTOM PLAN</option>
                      <option>GENERAL PLAN</option>
                    </select>
                  </span>
                </div>
                <br />
                <div className="service-label">
                  <label> Services Required* </label>
                  <span className="wrap-services">
                    <span>
                      <input
                        type="checkbox"
                        name="services-required[]"
                        value="Graphic Design"
                      />
                      <span className="text">Graphic Design</span>
                    </span>
                    <span>
                      <input
                        type="checkbox"
                        name="services-required[]"
                        value="UI/UX Design"
                      />
                      <span className="text">UI/UX Design</span>
                    </span>
                    <span className="wpcf7-list-item-label">
                      <input
                        type="checkbox"
                        name="services-required[]"
                        value="SAAS Development"
                      />
                      <span className="text">SAAS Development </span>
                    </span>
                    <span className="wpcf7-list-item-label">
                      <input
                        type="checkbox"
                        name="services-required[]"
                        value="Social Media Growth"
                      />
                      <span className="text">Social Media Growth</span>
                    </span>
                    <span className="wpcf7-list-item-label">
                      <input
                        type="checkbox"
                        name="services-required[]"
                        value="Brand Research"
                      />
                      <span className="text">Brand Research</span>
                    </span>
                  </span>

                </div>
              </div>
              <br />
              <span className="form-last">
                <textarea
                  cols="40"
                  rows="6"
                  aria-invalid="false"
                  placeholder="Please provide any extra information/ specific requirement in detail. Avoid placing any URL links in this field."
                  name="remarks"
                ></textarea>
                <span className="submit-area">
                  <input className="submit" type="submit" value="Submit" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <h1>Contact Information</h1>
        <div className="boxes">
          <div className="support-mail">
            <img src={Email} />
            <div className="premium-icon-box-text-wrap">
              <h4 className="premium-icon-box-title">AGENCY SUPPORT</h4>
              <div className="premium-icon-box-content">
                info@startingcore.com <br /> startingcore@gmail.com
              </div>
            </div>
          </div>

          <div className="support-mail">
            <img src={Time} />
            <div className="premium-icon-box-text-wrap">
              <h4 className="premium-icon-box-title">WORKING HOURS</h4>

              <div className="premium-icon-box-content">
                Monday-Friday: 10:00-18:00 <br /> Saturday: 11:00-17:00
              </div>
            </div>
          </div>

          <div className="support-mail">
            <img src={Phone} />
            <div className="premium-icon-box-text-wrap">
              <h4 className="premium-icon-box-title">AGENCY PHONES</h4>
              <div className="premium-icon-box-content">
                Phone No:<br></br> +91 8209388232
              </div>
            </div>
          </div>

          <div className="support-mail">
            <img src={Location} />
            <div className="premium-icon-box-text-wrap">
              <h4 className="premium-icon-box-title">AGENCY ADDRESS</h4>
              <div className="premium-icon-box-content">  
                Mumbai, Delhi,<br/> Jaipur
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="social-links">
        <h1>Social Links</h1>
        <a href="https://www.instagram.com/starting_core?igsh=MzRlODBiNWFlZA=="
          title="Instagram" target="_blank">
          <img src={Instagram} />
        </a>
        <a href="mailto:info@startingcore.com" target="_blank">
          <img src={Email} />
        </a>
        <a href="https://www.linkedin.com/company/starting-core/"
          title="Linkedin" target="_blank">
          <img src={Linkedin} />
        </a>
      </div>
    </>
  );
};

export default Contact;
