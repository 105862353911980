import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "./style.css";
import "swiper/swiper.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";

import { Autoplay, EffectCoverflow, Pagination, Navigation } from "swiper";

export default function App() {
  return (
    <>
      <div className="slider-div">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 10,
            stretch: 100,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }}
          loop={true}
          navigation={false}
          modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
          className="mySwiper"
        >

          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/client1.png" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/client3.png" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/balaji.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/avenue.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/agricoreslider.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/Aahvaan.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/connectingslider.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/cupsofsmiles.png" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/ecbslider.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/eternal.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/growuedits.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/multiphaseslider.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/ngo.png" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/sdrfslider.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/sliderkontent.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/unicrop.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/solv.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/innerloop.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="slider">
            <div className="profile-sect">
              <div className="profile-sec">
                <img className="slider-image" src="./images/iiicell.jpg" alt="logo" />
              </div>
            </div>
          </SwiperSlide>
          {/* <h1 className="textWhy font-face-sa">Why We?</h1> */}
        </Swiper>
      </div>
    </>
  );
}
